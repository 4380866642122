export default {
    SET_USUARIO(state, { nome, email }) {
      state.usuario = { nome, email };
    },

    //Mutations Clientes
    SET_CLIENTE(state, cliente) {
      state.cliente = cliente;
    },
    SET_CLIENTES(state, clientes) {
      state.listaClientes = clientes;
    },
    ADICIONAR_CLIENTE(state, cliente) {
      state.listaClientes.push(cliente);
    },
    ATUALIZAR_CLIENTE(state, clienteAlteradoDTO) {
      // Encontra o índice do cliente na listaClientes usando o ID
      const index = state.listaClientes.findIndex(cliente => cliente.id === clienteAlteradoDTO.id);
  
      if (index !== -1) {
        // Atualiza o cliente existente na listaClientes
        state.listaClientes.splice(index, 1, clienteAlteradoDTO);
      } else {
        // Se o cliente não for encontrado, adiciona-o.
        state.listaClientes.push(clienteAlteradoDTO);
      }
    },

    //Mutations Empresa
    SET_EMPRESA(state, empresa) {
      state.empresa = empresa;
    },
    SET_EMPRESAS(state, empresas) {
      state.listaEmpresas = empresas;
    },
    ADICIONAR_EMPRESA(state, empresa) {
      state.listaEmpresas.push(empresa);
    },
    ATUALIZAR_EMPRESA(state, empresaAlteradaDTO) {
      const index = state.listaEmpresas.findIndex(empresa => empresa.id === empresaAlteradaDTO.id);
  
      if (index !== -1) {
        state.listaEmpresas.splice(index, 1, empresaAlteradaDTO);
      } else {
        state.listaEmpresas.push(empresaAlteradaDTO);
      }
    },

    //Mutations Motorista
    SET_MOTORISTA(state, motorista) {
      state.motorista = motorista;
    },
    SET_MOTORISTAS(state, motoristas) {
      state.listaMotoristas = motoristas;
    },
    ADICIONAR_MOTORISTA(state, motorista) {
      state.listaMotoristas.push(motorista);
    },
    ATUALIZAR_MOTORISTA(state, motoristaAlteradoDTO) {
      const index = state.listaMotoristas.findIndex(motorista => motorista.id === motoristaAlteradoDTO.id);
  
      if (index !== -1) {
        state.listaMotoristas.splice(index, 1, motoristaAlteradoDTO);
      } else {
        state.listaMotoristas.push(motoristaAlteradoDTO);
      }
    },

    //Mutations Equipamentos
    SET_EQUIPAMENTO(state, equipamento) {
      state.equipamento = equipamento;
    },
    SET_EQUIPAMENTOS(state, equipamentos) {
      state.listaEquipamentos = equipamentos;
    },
    ADICIONAR_EQUIPAMENTO(state, equipamento) {
      state.listaEquipamentos.push(equipamento);
    },
    ATUALIZAR_EQUIPAMENTO(state, equipamentoAlteradoDTO) {
      const index = state.listaEquipamentos.findIndex(equipamento => equipamento.id === equipamentoAlteradoDTO.id);
  
      if (index !== -1) {
        state.listaEquipamentos.splice(index, 1, equipamentoAlteradoDTO);
      } else {
        state.listaEquipamentos.push(equipamentoAlteradoDTO);
      }
    },

    //Mutations Veículos
    SET_VEICULO(state, veiculo) {
      state.veiculo = veiculo;
    },
    SET_VEICULOS(state, veiculos) {
      state.listaVeiculos = veiculos;
    },
    ADICIONAR_VEICULO(state, veiculo) {
      state.listaVeiculos.push(veiculo);
    },
    ATUALIZAR_VEICULO(state, veiculoAlteradoDTO) {
      const index = state.listaVeiculos.findIndex(veiculo => veiculo.id === veiculoAlteradoDTO.id);
  
      if (index !== -1) {
        state.listaVeiculos.splice(index, 1, veiculoAlteradoDTO);
      } else {
        state.listaVeiculos.push(veiculoAlteradoDTO);
      }
    },

    //Mutations Locacoes
    SET_LOCACAO(state, locacao) {
      state.locacao = locacao;
    },
    SET_LOCACOES(state, locacoes) {
      state.listaLocacoes = locacoes;
    },
    ADICIONAR_LOCACAO(state, locacao) {
      state.listaLocacoes.push(locacao);
    },
    ATUALIZAR_LOCACAO(state, locacaoAlteradaDTO) {
      const index = state.listaLocacoes.findIndex(locacao => locacao.id === locacaoAlteradaDTO.id);
  
      if (index !== -1) {
        state.listaLocacoes.splice(index, 1, locacaoAlteradaDTO);
      } else {
        state.listaLocacoes.push(locacaoAlteradaDTO);
      }
    },
  };
