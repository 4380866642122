export function isAuthenticated() {
    return localStorage.getItem('authToken'); // Verifica se o token existe
}

export function login(token) {
    localStorage.setItem('authToken', token); // Salva o token no localStorage
}

export function logout() {
    localStorage.removeItem('authToken'); // Remove o token do localStorage    
}
