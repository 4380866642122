import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apiClient from './services/axios';
import ContentHeader from './components/ContentHeader.vue'
import AppContent from './components/AppContent.vue'
import FloatingVue from 'floating-vue';
import VueTheMask from 'vue-the-mask';
import 'floating-vue/dist/style.css';

const app = createApp(App)
app.config.globalProperties.$axios = apiClient;
app.component('content-header', ContentHeader)
app.component('app-content', AppContent)
app.use(FloatingVue);
app.use(VueTheMask);
app.use(store).use(router).mount('#app')