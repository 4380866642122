<template>
  <content-header title="Página inicial" icon="fas fa-home" />
  <app-content class="d-flex flex-column min-vh-100">
    <div class="row flex-grow-1">
      <div class="col-12 d-flex">
        <div class="card w-100 d-flex flex-column h-100">
          <div class="card-body flex-grow-1 overflow-auto">
            <section class="content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-info">
                      <div class="inner">
                        <h3>{{ indicadores.locacoesHoje }}</h3>
                        <p>Locações Hoje</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-bag"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        Mais informações <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>{{ indicadores.retiradasHoje }}</h3>
                        <p>Retiradas Hoje</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        Mais informações <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>{{ indicadores.vencendoHoje }}</h3>
                        <p>Vencendo Hoje</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-person-add"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        Mais informações <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-danger">
                      <div class="inner">
                        <h3>{{ indicadores.vencidas }}</h3>
                        <p>Vencidas</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        Mais informações <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Google Maps -->
                <div class="row">
                  <div class="col-12">
                    <div id="map" style="height: 600px"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </app-content>
</template>


<script>
/* global google */
import apiClient from '../services/axios';
export default {
  name: "HomePage",
  data() {
    return {
      indicadores: {
        locacoesHoje: 0,
        retiradasHoje: 0,
        vencendoHoje: 0,
        vencidas: 0,
        locacoesConcluidas: [],
      },
      map: null,
    };
  },
  async mounted() {
    if (!window.google || !window.google.maps) {
      
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBnc1oytArT-GmLofK6KAGCXHOuqB9NrKo&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      window.initMap = this.initMap;
    } else {
      this.initMap();
    }

    this.carregarIndicadores();
    this.carregarLocacoesConcluidas();
  },
  methods: {
    initMap() {
      if (!this.map) {
        
        
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: -22.1357097, lng: -51.3934799 },
          zoom: 14,
        });

        this.adicionarMarcadores();
      }
    },
    
    async carregarIndicadores() {
      try {
        const response = await apiClient.get('/Indicadores/ObterIndicadores');
        this.indicadores = { ...this.indicadores, ...response.data };
      } catch (error) {
        console.error("Erro ao carregar os indicadores:", error);
      }
    },
    
    async carregarLocacoesConcluidas() {
      try {
        const response = await apiClient.get("/Indicadores/ObterLocacoesConcluidas");
        
        this.indicadores.locacoesConcluidas = response.data.map((locacao) => {
          const precoTotalLocacao = locacao.itensLocacao.reduce((total, item) => {
            return total + item.precoTotal;
          }, 0);
          
          return {
            ...locacao,
            precoTotalLocacao
          };
        });

        this.adicionarMarcadores();
        
      } catch (error) {
        console.error("Erro ao carregar as locações concluídas:", error);
      }
    },


    adicionarMarcadores() {
      if (!this.map) {
        console.error("Mapa não foi inicializado corretamente.");
        return;
      }

      this.indicadores.locacoesConcluidas.forEach((locacao) => {
        const geocoder = new google.maps.Geocoder();
        const enderecoCompleto = `${locacao.endereco}, ${locacao.cidade}, ${locacao.estado}`;

        // Converte o endereço em coordenadas (geocoding)
        geocoder.geocode({ address: enderecoCompleto }, (results, status) => {
          if (status === "OK" && results[0]) {
            const location = results[0].geometry.location;
            
            const marker = new google.maps.Marker({
              map: this.map,
              position: location,
              title: locacao.clienteNome,
            });

            const infoWindowContent = `
              <div style="font-family: Arial, sans-serif; padding: 10px; max-width: 250px; color: #333;">
                <h3 style="margin: 0; font-size: 18px; color: #2c3e50;">${locacao.clienteNome}</h3>
                <p style="margin: 5px 0; font-size: 14px;">
                  <strong>Endereço:</strong> 
                  <span style="color: #7f8c8d;">${locacao.endereco}, ${locacao.cidade}, ${locacao.estado}</span>
                </p>
                <p style="margin: 5px 0; font-size: 14px;">
                  <strong>Preço Total da Locação:</strong> 
                  <span style="color: #27ae60;">R$ ${locacao.precoTotalLocacao.toFixed(2)}</span>
                </p>
              </div>
            `;

            const infoWindow = new google.maps.InfoWindow({
              content: infoWindowContent,
            });

            marker.addListener('click', () => {
              infoWindow.open(this.map, marker);
            });

          } else {
            console.error(`Erro ao converter endereço: ${enderecoCompleto}`);
          }
        });
      });
    },
  },
};
</script>


<style scoped>
#map {
  width: 100%;
  height: 100%;
}
</style>
