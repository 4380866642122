<template>
  <div :class="['login-box', 'p-0', { 'ml-3': isMobile }]">
    <div class="login-logo">
      <a href="/"><b>{{ $store.state.app.name }}</b></a>
    </div>

    <div :class="['card', { 'error-card': errorMessage }]">
      <div class="card-body login-card-body">
        
        <p class="login-box-msg">Faça login para iniciar sua sessão</p>
        
        <form @submit.prevent="handleLogin">
          <div class="input-group mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email"
              required
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Senha"
              required
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" v-model="rememberMe" class="mr-2" />
                <label for="remember"> Lembrar senha </label>
              </div>
            </div>

            <div class="col-12">
              <button v-if="!loading" type="submit" class="btn btn-primary btn-block" :disabled="loading">
                Entrar
              </button>

              <button v-if="loading" class="btn btn-primary btn-block" type="button" disabled>
                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span role="status" class="ml-1">Entrando</span>
              </button>
            </div>
          </div>
        </form>       

        <div class="mt-5 text-center">
          <button class="btn btn-block btn-outline-primary" @click="registrar">
            <i class="fas fa-user-plus mr-2"></i> Registre-se
          </button>
          <button class="btn btn-block btn-outline-danger" @click="esqueciSenha">
            <i class="fas fa-key mr-2"></i> Esqueci minha senha
          </button>
        </div>

        <transition name="fade">
          <div v-if="errorMessage" class="alert alert-danger mt-3 text-center" role="alert">
            {{ errorMessage }}
          </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MessageBox from "@/shared/sweetAlert2";

export default {
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: '',
      loading: false,
      isMobile: false
    };
  },

  mounted() {
    this.detectMobile();
    window.addEventListener("resize", this.detectMobile);

    document.title = `Login | ${this.$store.state.app.name}`;
    document.body.classList.remove('sidebar-mini');
    document.body.classList.add('login-page');

    const savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      this.email = savedEmail;
      this.rememberMe = true;
    }
  },

  beforeUnmount() {
    document.body.classList.add('sidebar-mini');
    document.body.classList.remove('login-page');
  },

  methods: {
    ...mapActions(['efetuarLogin']),

    detectMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    
    async handleLogin() {
      this.loading = true;
      this.errorMessage = '';

      try {
        await this.efetuarLogin({
          email: this.email, 
          password: this.password, 
          rememberMe: this.rememberMe
        });
        
        this.loading = false;
        this.$router.push('/inicio');

      } catch (error) {

        this.loading = false;
        
        if (typeof error === 'string') {
          this.errorMessage = error;
        } 
        else if (error.response && error.response.data) {
          this.errorMessage = error.response.data;
        } 
        else if (!error.response) {
          this.errorMessage = 'Não foi possível conectar ao servidor. Verifique sua conexão com a internet.';
        } 
        else {
          this.errorMessage = 'Ocorreu um erro ao efetuar o login. Por favor, tente novamente mais tarde.';
        }
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
      }
    },
    registrar() {
      MessageBox.info("Acione a Locamais para adicionar uma nova licença de usuário!", "Atenção");
    },
    
    esqueciSenha() {
      MessageBox.toastSuccess("Enviamos um E-mail para redefinir a sua senha!");
    }
}

};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.3s ease-in-out, transform 1.3s ease-in-out, border-color 1.3s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.card {
  box-sizing: border-box;
  border: 2px solid transparent; 
  min-height: 450px;
  transition: border-color 1.3s ease-in-out;
}

.error-card {
  border-color: red;
}

.fade-enter-active .error-card, .fade-leave-active .error-card {
  transition: border-color 1.3s ease-in-out;
}
</style>
