import axios from 'axios';
import { isAuthenticated } from '@/services/auth.js'

const apiClient = axios.create({
  // baseURL: 'https://localhost:7188/api/',
  baseURL: 'https://locamaiswebapi-c3f3c6aaghdparcg.brazilsouth-01.azurewebsites.net/api/', 
  headers: {
    'Content-Type': 'application/json',
  }
});

// Adiciona um interceptor para incluir o token JWT em todas as requisições
apiClient.interceptors.request.use(
  (config) => {
    const token = isAuthenticated(); // Obtém o token do localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Adiciona o token ao cabeçalho Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
